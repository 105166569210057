import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {Button, FormGroup, FormControl, Form, Alert} from 'react-bootstrap';
import './Login.css';
import {BACKEND_URL} from '../../variables.js';

const axios = require('axios');

const Login = () => {
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [message, setMessage] = useState('');
	const history = useHistory();

	const validateForm = () => {
		return username.length > 0 && password.length > 0;
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setMessage('');
		axios
			.post(
				`${BACKEND_URL}auth/login`,
				{
					username: username,
					password: password,
				},
				{
					withCredentials: true,
					credentials: 'include',
				}
			)
			.then((res) => {
				if (res.status === 200) {
					localStorage.setItem('accessToken', res.data.accessToken);
					localStorage.setItem('refreshToken', res.data.refreshToken);
					history.push('/');
				}
				console.log(res);
			})
			.catch((err) => {
				console.log(err);
				err.response && setMessage(err.response.data.message);
			});
	};

	return (
		<div className='Login'>
			{message.length > 0 && (
				<Alert className='alert' variant='danger'>
					{message}
				</Alert>
			)}
			<Form onSubmit={handleSubmit}>
				<FormGroup controlId='email'>
					<Form.Label>Brukernavn</Form.Label>
					<FormControl
						autoFocus
						type='text'
						value={username}
						onChange={(e) => setUsername(e.target.value)}
					/>
				</FormGroup>
				<FormGroup controlId='password'>
					<Form.Label>Password</Form.Label>
					<FormControl
						value={password}
						onChange={(e) => setPassword(e.target.value)}
						type='password'
					/>
				</FormGroup>
				<Button
					block
					id='loginBtn'
					disabled={!validateForm()}
					type='submit'>
					Login
				</Button>
			</Form>
		</div>
	);
};
export default Login;
