import React, {useState, useEffect} from 'react';

import NavBar from '../NavBar/NavBar.jsx';

import {BACKEND_URL} from '../../variables';

import './Arrangementer.css';
import AuthHelperMethods from '../../AuthHelperMethods';
import {Form, Row, Col, Input, Button, FormGroup, Label} from 'reactstrap';

import ArrTable from './ArrTable.jsx';

const axios = require('axios');

const Arrangementer = () => {
	const Auth = new AuthHelperMethods();
	const [tidligereArr, setTidligereArr] = useState([]);
	const [kommendeArr, setKommendeArr] = useState([]);
	const [loading, setLoading] = useState(true);
	const [newArr, setNewArr] = useState({});

	useEffect(() => {
		getArr();
	}, []);

	const getArr = async () => {
		await axios
			.get(`${BACKEND_URL}arrangementer`)
			.then((res) => {
				const arrs = res.data;
				setTidligereArr(
					arrs
						.filter((arr) => new Date(arr.date) < new Date())
						.sort((a, b) => {
							var keyA = new Date(a.date),
								keyB = new Date(b.date);
							if (keyA < keyB) return -1;
							if (keyA > keyB) return 1;
							return 0;
						})
				);
				setKommendeArr(
					arrs
						.filter((arr) => new Date(arr.date) >= new Date())
						.sort((a, b) => {
							var keyA = new Date(a.date),
								keyB = new Date(b.date);
							if (keyA < keyB) return -1;
							if (keyA > keyB) return 1;
							return 0;
						})
				);
				console.log(arrs);
			})
			.then(() => {
				setLoading(false);
			});
	};

	const ddmmyyyy = (date) => {
		let yyyy = date.getFullYear();
		let mm = date.getMonth() + 1;
		let dd = date.getDate();
		return dd + '/' + mm + '/' + yyyy;
	};

	const onFormChange = (e) => {
		setNewArr({...newArr, [e.target.name]: e.target.value});
	};

	const onFormSubmit = async (e) => {
		e.preventDefault();
		await axios
			.post(`${BACKEND_URL}arrangementer/new`, newArr, {
				headers: {authorization: `Bearer ${Auth.getAccessToken()}`},
			})
			.then((res) => {
				if (res.status === 204) {
					alert('La inn nytt arrangement!');
					getArr();
				} else {
					alert(
						'Usikker på om arrangementet ble lagt inn. Dobbeltsjekk at det ble godkjent!'
					);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	return (
		<div className='arrangementer'>
			<NavBar />
			<div id='arrangementerContainer'>
				<div id='nyttArr'>
					<h1>Nytt arrangement</h1>
					<Form onChange={(e) => onFormChange(e)}>
						<Row form>
							<Col md={6}>
								<FormGroup>
									<Label for='date'>Dato</Label>
									<Input type='date' name='date' id='date' />
								</FormGroup>
							</Col>
							<Col md={6}>
								<FormGroup>
									<Label for='time'>Tid</Label>
									<Input type='time' name='time' id='time' />
								</FormGroup>
							</Col>
						</Row>
						<Row form>
							<Col md={12}>
								<FormGroup>
									<Label for='description'>Beskrivelse</Label>
									<Input
										type='textarea'
										name='description'
										id='description'
									/>
								</FormGroup>
							</Col>
						</Row>
						<Button onClick={(e) => onFormSubmit(e)}>
							Legg inn arrangement
						</Button>
					</Form>
				</div>
				{!loading && (
					<div id='tidlKommendeArr'>
						<div id='kommendeArr'>
							<h2>Kommende arr</h2>
							<ArrTable
								tableId='kommendeArrTable'
								firstHeadId='firstHeadKommende'
								arr={kommendeArr}
								getArr={getArr}
							/>
						</div>
						<div id='divider'></div>
						<div id='tidligereArr'>
							<h2>Tidligere arr</h2>
							<ArrTable
								tableId='tidligereArrTable'
								firstHeadId='firstHeadtidligere'
								arr={tidligereArr}
								getArr={getArr}
							/>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default Arrangementer;
