import React, {useState} from 'react';
import AuthHelperMethods from '../../AuthHelperMethods';
import {useHistory} from 'react-router-dom';
import {useInterval} from '../../hooks';

import {Input} from 'reactstrap';
import NavBar from '../NavBar/NavBar.jsx';

import './Home.css';
import parrot from './parrot.gif';

const axios = require('axios');

const Home = () => {
	const history = useHistory();
	const [bookings, setBookings] = useState([]);
	const Auth = new AuthHelperMethods();

	useInterval(() => {
		Auth.refreshToken();
		console.log('Update access token');
	}, 1500000);

	const getBookings = () => {
		axios
			.get('http://localhost:3001/booking', {
				headers: {authorization: 'Bearer ' + Auth.getAccessToken()},
			})
			.then((res) => {
				setBookings(res.data);
			});
	};

	return (
		<div className='home'>
			<NavBar />
			<h1>🍻 Hei og velkommen til Realfagskjellerens adminside 🍻 </h1>
			<p>
				Her kan du ikke gjøre veldig mye enda, men jeg håper det kommer
				litt snax her etterhvert ❤️{' '}
			</p>
			<p>🍻🍶🥂🍷🥃🍹🍸🍾🍟🍔🍉🍓🍈🍑🥭🌮💯💯⚠️👅</p>
			<img src={parrot} alt='parrot' />
		</div>
	);
};

export default Home;

/* 
<button
				onClick={() => {
					getBookings();
				}}>
				Hent bookinger
			</button>
			{bookings.map((booking) => (
				<div>
					{booking.name}, {booking.mobile}, {booking.tableid},{' '}
					{booking.antall}, {booking.fra} - {booking.til},{' '}
					{booking.dato}
				</div>
			))}
*/
