import React, {useEffect, useState} from 'react';

import {BACKEND_URL} from '../../variables.js';
import AuthHelperMethods from '../../AuthHelperMethods';
import {useInterval} from '../../hooks.js';

import NavBar from '../NavBar/NavBar.jsx';
import {Input, Label} from 'reactstrap';

import './Booking.css';

const axios = require('axios');

const Booking = () => {
	const [date, setDate] = useState(new Date());
	const Auth = new AuthHelperMethods();
	const [bookings, setBookings] = useState([]);
	const [stua, setStua] = useState([]);
	const [beerPong, setBeerPong] = useState([]);
	const [klinerommet, setKlinerommet] = useState([]);
	const [ludol, setLudol] = useState([]);

	useEffect(() => {
		getBookings(new Date());
	}, []);

	useInterval(() => {
		Auth.refreshToken();
	}, 1500000);

	const getBookings = (date) => {
		if (date instanceof Date) {
			const tmpDate = date.toLocaleDateString().split('/');
			date = tmpDate[2] + '-' + tmpDate[1] + '-' + tmpDate[0];
		}
		Auth.fetch(`${BACKEND_URL}booking/bookingOnDate/${date}`).then(
			(res) => {
				let tmpBookings = {
					stua: [],
					klinerommet: [],
					ludøl: [],
					beer_pong: [],
				};

				console.log(res);

				res.forEach((b) => {
					tmpBookings[
						b.bordnavn === 'Beer Pong'
							? 'beer_pong'
							: b.bordnavn.toLowerCase()
					].push(b);
				});

				setStua(
					Object.entries(tmpBookings)
						.filter((b) => b[0] === 'stua')
						.map((b) => b[1])[0]
				);
				setKlinerommet(
					Object.entries(tmpBookings)
						.filter((b) => b[0] === 'klinerommet')
						.map((b) => b[1])[0]
				);
				setLudol(
					Object.entries(tmpBookings)
						.filter((b) => b[0] === 'ludøl')
						.map((b) => b[1])[0]
				);
				setBeerPong(
					Object.entries(tmpBookings)
						.filter((b) => b[0] === 'beer_pong')
						.map((b) => b[1])[0]
				);

				setBookings(res);
			}
		);
	};

	const onCheckboxChange = (e) => {
		const mott = e.target.checked;
		const bookingid = parseInt(e.target.value);
		axios
			.post(
				`${BACKEND_URL}booking/changeMott`,
				{
					id: bookingid,
					mott: mott,
				},
				{
					headers: {
						authorization: `Bearer ${Auth.getAccessToken()}`,
					},
				}
			)
			.then((res) => {
				if (res.status === 200 && res.data.changedRows > 0) {
					const b = bookings.filter(
						(b) => b.bookingid === parseInt(bookingid)
					)[0];
					/*
					const tmpBookings = bookings.filter(
						(b) => b.bookingid !== bookingid
					);
					b.mott = mott;
					setBookings([...tmpBookings, b]);
					if (b.bordnavn === 'Stua') {
						const tmpStua = stua.filter(
							(b) => b.bookingid !== bookingid
						);
						setStua([...tmpStua, b]);
					} else if (b.bordnavn === 'Klinerommet') {
						setKlinerommet([
							...klinerommet.filter(
								(b) => b.bookingid !== bookingid
							),
							b,
						]);
					} else if (b.bordnavn === 'Beer Pong') {
						setBeerPong([
							...beerPong.filter(
								(b) => b.bookingid !== bookingid
							),
							b,
						]);
					} else {
						setLudol([
							...ludol.filter((b) => b.bookingid !== bookingid),
							b,
						]);
                    }*/

					getBookings(date);

					let message = 'Satt ' + b.name + ' til ';
					if (mott) {
						message += 'møtt!';
					} else {
						message += 'ikke møtt!';
					}
					alert(message);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const onDateChange = (e) => {
		setDate(new Date(e.target.value));
		getBookings(e.target.value);
	};

	const deleteBooking = (e) => {
		const bookingid = e.target.getAttribute('data-bookingid');

		axios
			.post(
				`${BACKEND_URL}booking/deleteBooking`,
				{id: bookingid},
				{
					headers: {authorization: `Bearer ${Auth.getAccessToken()}`},
				}
			)
			.then((res) => {
				if (res.status === 200) {
					alert('Slettet booking! :D');
					setBookings(
						bookings.filter(
							(b) => parseInt(b.bookingid) !== parseInt(bookingid)
						)
					);
				}
			})
			.catch((err) => {
				alert('Det skjedde en feil ved sletting av bookingen :(');
				console.log(err);
			});
	};

	return (
		<div className='booking'>
			<NavBar />
			<div id='bookingContainer'>
				<Label for='date'>Velg dato for booking</Label>
				<Input
					type='date'
					name='date'
					onChange={(e) => {
						onDateChange(e);
					}}
				/>{' '}
				{bookings.length === 0 ? (
					<p>
						Ingen bookinger på dato{' '}
						{date.toLocaleDateString('nb-NO')}
					</p>
				) : (
					<table id='bookingsTable'>
						<thead>
							<tr id='firstHead'>
								<th>Navn</th>
								<th>Mobil</th>
								<th>Antall</th>
								<th>Fra</th>
								<th>Til</th>
								<th>Dato</th>
								<th>Bord</th>
								<th>Møtt</th>
								<th>
									<span style={{color: 'red'}}>Slett</span>
								</th>
							</tr>
						</thead>
						<tbody>
							{stua.length > 0 && (
								<tr>
									<th colSpan='9'>
										Stua,{' '}
										<span>
											18:00-21:00:{' '}
											{stua
												.filter(
													(b) =>
														b.fra === '18:00:00' &&
														b.til === '21:00:00'
												)
												.map((b) => b.antall)
												.reduce((a, b) => a + b, 0)}
										</span>
										,{' '}
										<span style={{color: 'red'}}>
											21:00-00:00:{' '}
											{stua
												.filter(
													(b) =>
														b.fra === '21:00:00' &&
														b.til === '23:59:59'
												)
												.map((b) => b.antall)
												.reduce((a, b) => a + b, 0)}
										</span>
										,{' '}
										<span>
											18:00-00:00:{' '}
											{stua
												.filter(
													(b) =>
														b.fra === '18:00:00' &&
														b.til === '23:59:59'
												)
												.map((b) => b.antall)
												.reduce((a, b) => a + b, 0)}
										</span>
										,{' '}
										<span>
											sum totalt:{' '}
											{stua
												.map((b) => b.antall)
												.reduce((a, b) => a + b, 0)}
										</span>
									</th>
								</tr>
							)}
							{stua.map((b) => (
								<tr
									key={b.bookingid}
									id={'booking' + b.bookingid}>
									<td>{b.name}</td>
									<td>{b.mobile}</td>
									<td>{b.antall}</td>
									<td>{b.fra}</td>
									<td>{b.til}</td>
									<td>{b.dato}</td>
									<td>{b.bordnavn}</td>
									<td className='mottCheck'>
										<input
											type='checkbox'
											value={b.bookingid}
											onChange={(e) => {
												onCheckboxChange(e);
											}}
											checked={b.mott}
										/>
									</td>
									<td>
										<span
											style={{
												color: 'red',
												cursor: 'pointer',
											}}
											data-bookingid={b.bookingid}
											onClick={(e) => {
												deleteBooking(e);
											}}>
											X
										</span>
									</td>
								</tr>
							))}
							{ludol.length > 0 && (
								<tr>
									<th colSpan='9'>
										Ludøl,{' '}
										<span>
											18:00-21:00:{' '}
											{ludol
												.filter(
													(b) =>
														b.fra === '18:00:00' &&
														b.til === '21:00:00'
												)
												.map((b) => b.antall)
												.reduce((a, b) => a + b, 0)}
										</span>
										,{' '}
										<span style={{color: 'red'}}>
											21:00-00:00:{' '}
											{ludol
												.filter(
													(b) =>
														b.fra === '21:00:00' &&
														b.til === '23:59:59'
												)
												.map((b) => b.antall)
												.reduce((a, b) => a + b, 0)}
										</span>
										,{' '}
										<span>
											18:00-00:00:{' '}
											{ludol
												.filter(
													(b) =>
														b.fra === '18:00:00' &&
														b.til === '23:59:59'
												)
												.map((b) => b.antall)
												.reduce((a, b) => a + b, 0)}
										</span>
										,{' '}
										<span>
											sum totalt:{' '}
											{ludol
												.map((b) => b.antall)
												.reduce((a, b) => a + b, 0)}
										</span>
									</th>
								</tr>
							)}
							{ludol.map((b) => (
								<tr
									key={b.bookingid}
									id={'booking' + b.bookingid}>
									<td>{b.name}</td>
									<td>{b.mobile}</td>
									<td>{b.antall}</td>
									<td>{b.fra}</td>
									<td>{b.til}</td>
									<td>{b.dato}</td>
									<td>{b.bordnavn}</td>
									<td className='mottCheck'>
										<input
											type='checkbox'
											value={b.bookingid}
											onChange={(e) => {
												onCheckboxChange(e);
											}}
											checked={b.mott}
										/>
									</td>
									<td>
										<span
											style={{
												color: 'red',
												cursor: 'pointer',
											}}
											data-bookingid={b.bookingid}
											onClick={(e) => {
												deleteBooking(e);
											}}>
											X
										</span>
									</td>
								</tr>
							))}
							{klinerommet.length > 0 && (
								<tr>
									<th colSpan='9'>
										Klinerommet,{' '}
										<span>
											18:00-21:00:{' '}
											{klinerommet
												.filter(
													(b) =>
														b.fra === '18:00:00' &&
														b.til === '21:00:00'
												)
												.map((b) => b.antall)
												.reduce((a, b) => a + b, 0)}
										</span>
										,{' '}
										<span style={{color: 'red'}}>
											21:00-00:00:{' '}
											{klinerommet
												.filter(
													(b) =>
														b.fra === '21:00:00' &&
														b.til === '23:59:59'
												)
												.map((b) => b.antall)
												.reduce((a, b) => a + b, 0)}
										</span>
										,{' '}
										<span>
											18:00-00:00:{' '}
											{klinerommet
												.filter(
													(b) =>
														b.fra === '18:00:00' &&
														b.til === '23:59:59'
												)
												.map((b) => b.antall)
												.reduce((a, b) => a + b, 0)}
										</span>
										,{' '}
										<span>
											sum totalt:{' '}
											{klinerommet
												.map((b) => b.antall)
												.reduce((a, b) => a + b, 0)}
										</span>
									</th>
								</tr>
							)}
							{klinerommet.map((b) => (
								<tr
									key={b.bookingid}
									id={'booking' + b.bookingid}>
									<td>{b.name}</td>
									<td>{b.mobile}</td>
									<td>{b.antall}</td>
									<td>{b.fra}</td>
									<td>{b.til}</td>
									<td>{b.dato}</td>
									<td>{b.bordnavn}</td>
									<td className='mottCheck'>
										<input
											type='checkbox'
											value={b.bookingid}
											onChange={(e) => {
												onCheckboxChange(e);
											}}
											checked={b.mott}
										/>
									</td>
									<td>
										<span
											style={{
												color: 'red',
												cursor: 'pointer',
											}}
											data-bookingid={b.bookingid}
											onClick={(e) => {
												deleteBooking(e);
											}}>
											X
										</span>
									</td>
								</tr>
							))}
							{beerPong.length > 0 && (
								<tr>
									<th colSpan='9'>
										Beer Pong,{' '}
										<span>
											18:00-21:00:{' '}
											{beerPong
												.filter(
													(b) =>
														b.fra === '18:00:00' &&
														b.til === '21:00:00'
												)
												.map((b) => b.antall)
												.reduce((a, b) => a + b, 0)}
										</span>
										,{' '}
										<span style={{color: 'red'}}>
											21:00-00:00:{' '}
											{beerPong
												.filter(
													(b) =>
														b.fra === '21:00:00' &&
														b.til === '23:59:59'
												)
												.map((b) => b.antall)
												.reduce((a, b) => a + b, 0)}
										</span>
										,{' '}
										<span>
											18:00-00:00:{' '}
											{beerPong
												.filter(
													(b) =>
														b.fra === '18:00:00' &&
														b.til === '23:59:59'
												)
												.map((b) => b.antall)
												.reduce((a, b) => a + b, 0)}
										</span>
										,{' '}
										<span>
											sum totalt:{' '}
											{beerPong
												.map((b) => b.antall)
												.reduce((a, b) => a + b, 0)}
										</span>
									</th>
								</tr>
							)}
							{beerPong.length > 0 &&
								beerPong.map((b) => (
									<tr
										key={b.bookingid}
										id={'booking' + b.bookingid}>
										<td>{b.name}</td>
										<td>{b.mobile}</td>
										<td>{b.antall}</td>
										<td>{b.fra}</td>
										<td>{b.til}</td>
										<td>{b.dato}</td>
										<td>{b.bordnavn}</td>
										<td className='mottCheck'>
											<input
												type='checkbox'
												value={b.bookingid}
												onChange={(e) => {
													onCheckboxChange(e);
												}}
												checked={b.mott}
											/>
										</td>
										<td>
											<span
												style={{
													color: 'red',
													cursor: 'pointer',
												}}
												data-bookingid={b.bookingid}
												onClick={(e) => {
													deleteBooking(e);
												}}>
												X
											</span>
										</td>
									</tr>
								))}
						</tbody>
					</table>
				)}
			</div>
		</div>
	);
};

export default Booking;

/* 
{bookings.map((b) => (
								<tr
									key={b.bookingid}
									id={'booking' + b.bookingid}>
									<td>{b.name}</td>
									<td>{b.mobile}</td>
									<td>{b.antall}</td>
									<td>{b.fra}</td>
									<td>{b.til}</td>
									<td>{b.dato}</td>
									<td>{b.bordnavn}</td>
									<td>
										<span
											style={{
												color: 'red',
												cursor: 'pointer',
											}}
											data-bookingid={b.bookingid}
											onClick={(e) => {
												deleteBooking(e);
											}}>
											X
										</span>
									</td>
								</tr>
							))}
*/

/* 
<tr>
								<th colSpan='8'>Stua</th>
							</tr>
							{stua.map((b) => (
								<tr
									key={b.bookingid}
									id={'booking' + b.bookingid}>
									<td>{b.name}</td>
									<td>{b.mobile}</td>
									<td>{b.antall}</td>
									<td>{b.fra}</td>
									<td>{b.til}</td>
									<td>{b.dato}</td>
									<td>{b.bordnavn}</td>
									<td>
										<span
											style={{
												color: 'red',
												cursor: 'pointer',
											}}
											data-bookingid={b.bookingid}
											onClick={(e) => {
												deleteBooking(e);
											}}>
											X
										</span>
									</td>
								</tr>
							))}
							<tr>
								<th colSpan='8'>Ludøl</th>
							</tr>
							{ludol.map((b) => (
								<tr
									key={b.bookingid}
									id={'booking' + b.bookingid}>
									<td>{b.name}</td>
									<td>{b.mobile}</td>
									<td>{b.antall}</td>
									<td>{b.fra}</td>
									<td>{b.til}</td>
									<td>{b.dato}</td>
									<td>{b.bordnavn}</td>
									<td>
										<span
											style={{
												color: 'red',
												cursor: 'pointer',
											}}
											data-bookingid={b.bookingid}
											onClick={(e) => {
												deleteBooking(e);
											}}>
											X
										</span>
									</td>
								</tr>
							))}
							<tr>
								<th colSpan='8'>Klinerommet</th>
							</tr>
							{klinerommet.map((b) => (
								<tr
									key={b.bookingid}
									id={'booking' + b.bookingid}>
									<td>{b.name}</td>
									<td>{b.mobile}</td>
									<td>{b.antall}</td>
									<td>{b.fra}</td>
									<td>{b.til}</td>
									<td>{b.dato}</td>
									<td>{b.bordnavn}</td>
									<td>
										<span
											style={{
												color: 'red',
												cursor: 'pointer',
											}}
											data-bookingid={b.bookingid}
											onClick={(e) => {
												deleteBooking(e);
											}}>
											X
										</span>
									</td>
								</tr>
							))}
							{beerPong.length > 0 && (
								<tr>
									<th colSpan='8'>Beer Pong</th>
								</tr>
							)}
							{beerPong.length > 0 &&
								beerPong.map((b) => (
									<tr
										key={b.bookingid}
										id={'booking' + b.bookingid}>
										<td>{b.name}</td>
										<td>{b.mobile}</td>
										<td>{b.antall}</td>
										<td>{b.fra}</td>
										<td>{b.til}</td>
										<td>{b.dato}</td>
										<td>{b.bordnavn}</td>
										<td>
											<span
												style={{
													color: 'red',
													cursor: 'pointer',
												}}
												data-bookingid={b.bookingid}
												onClick={(e) => {
													deleteBooking(e);
												}}>
												X
											</span>
										</td>
									</tr>
								))}
*/
