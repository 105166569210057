import React, {useState, useEffect} from 'react';
import {BACKEND_URL} from '../../variables';
import AuthHelperMethods from '../../AuthHelperMethods';

import {Input, Button} from 'reactstrap';
import NavBar from '../NavBar/NavBar.jsx';
import './Tables.css';

const axios = require('axios');

const Tables = () => {
	const [tables, setTables] = useState([]);
	const auth = new AuthHelperMethods();

	useEffect(() => {
		axios
			.get(`${BACKEND_URL}table/allTables`)
			.then((res) => {
				if (res.status === 200) {
					let tables = res.data;
					tables.sort((a, b) => {
						if (a.tableid < b.tableid) return -1;
						if (a.tableid > b.tableid) return 1;
						return 0;
					});
					setTables(tables);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	const handleInpChange = (e) => {
		const tableid = e.target.getAttribute('data-tableid');
		let table = tables.filter(
			(table) => table.tableid === parseInt(tableid)
		)[0];
		let otherTables = tables.filter(
			(table) => table.tableid !== parseInt(tableid)
		);
		table[e.target.name] = parseInt(e.target.value);
		otherTables.push(table);
		setTables(otherTables);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		axios
			.post(`${BACKEND_URL}table/updateTables`, tables, {
				headers: {authorization: `Bearer ${auth.getAccessToken()}`},
			})
			.then((res) => {
				alert(res.data.message);
			})
			.catch((err) => {
				console.log(err);
				alert('Noe gikk galt.');
			});
	};

	return (
		<div className='tables'>
			<NavBar />
			<div id='tablesContainer'>
				<h1>Se og endre bord</h1>
				<table>
					<thead>
						<tr>
							<td>Navn</td>
							<td>Kapasitet</td>
						</tr>
					</thead>
					<tbody>
						{tables
							.sort((a, b) => {
								if (a.tableid < b.tableid) return -1;
								if (a.tableid > b.tableid) return 1;
								return 0;
							})
							.map((table) => (
								<tr key={table.tableid}>
									<td>
										<Input
											className='tableInp'
											type='text'
											data-tableid={table.tableid}
											defaultValue={table.name}
											name='name'
											onChange={(e) => handleInpChange(e)}
										/>
									</td>
									<td>
										<Input
											type='number'
											defaultValue={table.capacity}
											min={0}
											data-tableid={table.tableid}
											className='tableInp'
											name='capacity'
											onChange={(e) => handleInpChange(e)}
										/>
									</td>
								</tr>
							))}
					</tbody>
				</table>
				<Button onClick={(e) => handleSubmit(e)}>
					Lagre endringer
				</Button>
			</div>
		</div>
	);
};

export default Tables;
