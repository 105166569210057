import React, {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import './App.css';
import AuthHelperMethods from './AuthHelperMethods';

import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link,
	Redirect,
} from 'react-router-dom';

import Home from './components/Home/Home.jsx';
import Login from './components/Login/Login.jsx';
import Booking from './components/Booking/Booking.jsx';
import Arrangementer from './components/Arrangementer/Arrangementer.jsx';
import Tables from './components/Tables/Tables.jsx';

const App = () => {
	const Auth = new AuthHelperMethods();
	const history = useHistory();

	useEffect(() => {
		console.log('Logged in: ' + Auth.loggedIn());
	}, []);

	return (
		<Router>
			<Switch>
				<Route
					path='/login'
					render={() =>
						Auth.loggedIn() ? <Redirect to='/' /> : <Login />
					}></Route>
				<Route
					path='/bookinger'
					render={() =>
						!Auth.loggedIn() ? (
							<Redirect to='/login' />
						) : (
							<Booking />
						)
					}></Route>
				<Route
					path='/arrangementer'
					render={() =>
						!Auth.loggedIn() ? (
							<Redirect to='login' />
						) : (
							<Arrangementer />
						)
					}
				/>
				<Route
					path='/bord'
					render={() =>
						!Auth.loggedIn() ? <Redirect to='login' /> : <Tables />
					}
				/>
				<Route
					path='/'
					render={() =>
						!Auth.loggedIn() ? <Redirect to='/login' /> : <Home />
					}></Route>
			</Switch>
		</Router>
	);
};

export default App;
