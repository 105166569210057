import React from 'react';
import AuthHelperMethods from '../../AuthHelperMethods';
import {BACKEND_URL} from '../../variables.js';
const axios = require('axios');

const ArrTable = (props) => {
	const Auth = new AuthHelperMethods();

	const ddmmyyyy = (date) => {
		let yyyy = date.getFullYear();
		let mm = date.getMonth() + 1;
		let dd = date.getDate();
		return dd + '/' + mm + '/' + yyyy;
	};

	const deleteArr = async (e) => {
		const id = e.target.getAttribute('data-arrid');
		await axios
			.post(
				`${BACKEND_URL}arrangementer/delete`,
				{id: id},
				{headers: {authorization: `Bearer ${Auth.getAccessToken()}`}}
			)
			.then((res) => {
				if (res.status === 200) {
					alert('Slettet booking!');
				}
				props.getArr();
			})
			.catch((err) => {
				alert('Noe gikk galt :(');
				console.log(err);
			});
	};

	return (
		<table id={props.tableId}>
			<thead id={props.firstHeadId}>
				<tr>
					<td>Dato</td>
					<td>Beskrivelse</td>
					<td>Slett</td>
				</tr>
			</thead>
			<tbody>
				{props.arr.map((arr) => (
					<tr key={arr.id}>
						<td>{ddmmyyyy(new Date(arr.date))}</td>
						<td>{arr.description}</td>
						<td>
							<span
								style={{
									color: 'red',
									cursor: 'pointer',
								}}
								data-arrid={arr.id}
								onClick={(e) => {
									deleteArr(e);
								}}>
								X
							</span>
						</td>
					</tr>
				))}
			</tbody>
		</table>
	);
};

export default ArrTable;
