import decode from 'jwt-decode';
import {BACKEND_URL} from './variables.js';
const axios = require('axios');

export default class AuthHelperMethods {
	// Initializing important variables
	constructor() {
		//THIS LINE IS ONLY USED WHEN YOU'RE IN PRODUCTION MODE!
		this.domain = 'http://localhost:3000'; // API server domain
	}

	loggedIn = () => {
		// Checks if there is a saved token and it's still valid
		let token = this.getAccessToken(); // Getting token from localstorage
		// console.log('TOKEN: ' + token);
		/*if (token && this.isTokenExpired(token)) {
			console.log('Heyhey');
			await this.refreshToken().then(
				(refreshToken) => (token = refreshToken)
			);
		}*/

		return !!token && !this.isTokenExpired(token); // handwaiving here
	};

	refreshToken = () => {
		const refreshToken = this.getRefreshToken();
		let token;
		axios
			.post(`${BACKEND_URL}auth/token`, {token: refreshToken})
			.then((res) => {
				this.setAccessToken(res.data.accessToken);
				token = res.data.accessToken;
			})
			.catch((err) => {
				console.log(err);
			});
		return token;
	};

	getRefreshToken = () => {
		return localStorage.getItem('refreshToken');
	};

	isTokenExpired = (token) => {
		try {
			const decoded = decode(token);
			if (decoded.exp < Date.now() / 1000) {
				// Checking if token is expired.
				return true;
			} else return false;
		} catch (err) {
			console.log(err);
			console.log(token);
			console.log('expired check failed! Line 42: AuthService.js');
			return false;
		}
	};

	getAccessToken = () => {
		return localStorage.getItem('accessToken');
	};

	setAccessToken = (token) => {
		localStorage.setItem('accessToken', token);
	};

	logout = async () => {
		// Clear user token and profile data from localStorage
		return new Promise(async (resolve, reject) => {
			await this.deleteTokens(
				this.getRefreshToken(),
				this.getAccessToken()
			)
				.then((res) => {
					if (res.status === 204) {
						localStorage.removeItem('accessToken');
						localStorage.removeItem('refreshToken');
						resolve();
					}
				})
				.catch((err) => {
					reject();
				});
		});
	};

	getUserFromToken = (token) => {
		try {
			const decoded = decode(token);
			return decoded.username;
		} catch (err) {
			console.log(err);
		}
		return null;
	};

	get = (data) => {
		if (this.isTokenExpired(this.getAccessToken())) {
			this.refreshToken();
		}
	};

	deleteTokens = (token, accessToken) => {
		return axios.post(
			`${BACKEND_URL}auth/logout`,
			{token: token},
			{
				headers: {
					authorization: `Bearer ${accessToken}`,
					'Content-Type': 'application/json',
				},
			}
		);
	};

	getConfirm = () => {
		// Using jwt-decode npm package to decode the token
		let answer = decode(this.getToken());
		console.log('Recieved answer!');
		return answer;
	};

	fetch = (url, options) => {
		// performs api calls sending the required authentication headers
		const headers = {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		};
		// Setting Authorization header
		// Authorization: Bearer xxxxxxx.xxxxxxxx.xxxxxx
		if (this.loggedIn()) {
			headers['Authorization'] = 'Bearer ' + this.getAccessToken();
		}

		return fetch(url, {
			headers,
			...options,
		})
			.then(this._checkStatus)
			.then((response) => response.json());
	};

	_checkStatus = (response) => {
		// raises an error in case response status is not a success
		if (response.status >= 200 && response.status < 300) {
			// Success status lies between 200 to 300
			return response;
		} else {
			var error = new Error(response.statusText);
			error.response = response;
			throw error;
		}
	};
}
