import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {
	Collapse,
	Navbar,
	NavbarToggler,
	NavbarBrand,
	Nav,
	NavItem,
	NavLink,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	NavbarText,
} from 'reactstrap';

import AuthHelperMethods from '../../AuthHelperMethods.jsx';

const NavBar = (props) => {
	const [isOpen, setIsOpen] = useState(false);
	const Auth = new AuthHelperMethods();
	const history = useHistory();

	const toggle = () => setIsOpen(!isOpen);

	return (
		<div id='navbar'>
			<Navbar color='dark' dark expand='md'>
				<NavbarBrand href='/'>Hjem</NavbarBrand>
				<NavbarToggler onClick={toggle} />
				<Collapse isOpen={isOpen} navbar>
					<Nav className='mr-auto' navbar>
						<NavItem>
							<NavLink
								style={{cursor: 'pointer'}}
								onClick={() => {
									history.push('/bookinger');
								}}>
								Bookinger
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink
								style={{cursor: 'pointer'}}
								onClick={() => {
									history.push('/arrangementer');
								}}>
								Arrangementer
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink
								style={{cursor: 'pointer'}}
								onClick={() => {
									history.push('/bord');
								}}>
								Se og endre bord
							</NavLink>
						</NavItem>
					</Nav>
					<NavbarText>
						<span
							style={{cursor: 'pointer'}}
							onClick={() => {
								Auth.logout().then((res) => {
									history.push('/login');
								});
							}}>
							Logg ut
						</span>
					</NavbarText>
				</Collapse>
			</Navbar>
		</div>
	);
};

export default NavBar;
